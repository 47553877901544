import React from 'react';

import Layout from '../components/v2/Layout';
import PageSection from '../components/v2/PageSection.jsx';
import Hero from '../components/v2/Hero';
import ContentBlock from '../components/v2/ContentBlock';
import PrevNextNavFooter from '../components/v2/ui-blocks/PrevNextNavFooter/PrevNextNavFooter';
import FontSelector from '../components/v2/ui-blocks/FontSelector/FontSelector';

import { pageLinks } from '../data/pageLinks.js';
import Tabs from '../components/v2/ui-blocks/Tabs';

import hero_img from '../images/5. Typography/typography-header-2560x1728.jpg';

import Typography_Body_Leading_svg from '../images/5. Typography/Desktop/Toyota_Desktop_Typography_Body_Leading.svg';
import Typography_Body_Paragraph_Spacing_svg from '../images/5. Typography/Desktop/Toyota_Desktop_Typography_Body_Paragraph_Spacing.svg';
import Typography_Body_Weight_svg from '../images/5. Typography/Desktop/Toyota_Desktop_Typography_Body_Weight.svg';

import Typography_Headline_Alignment_svg from '../images/5. Typography/Desktop/Toyota_Desktop_Typography_Headline_Alignment.svg';
import Typography_Headline_Kerning_svg from '../images/5. Typography/Desktop//Toyota_Desktop_Typography_Headline_Kerning.svg';
import Typography_Headline_Leading_svg from '../images/5. Typography/Desktop/Toyota_Desktop_Typography_Headline_Leading.svg';
import Typography_Headline_Weight_svg from '../images/5. Typography/Desktop/Toyota_Desktop_Typography_Headline_Weight.svg';

import Typography_Legal_Alignment_svg from '../images/5. Typography/Desktop/Toyota_Desktop_Typography_Legal_Alignment.svg';
import Typography_Legal_Leading_svg from '../images/5. Typography/Desktop/Toyota_Desktop_Typography_Legal_Leading.svg';
import Typography_Legal_Weight_svg from '../images/5. Typography/Desktop/Toyota_Desktop_Typography_Legal_Weight.svg';

import Typography_Subhead_Kerning_svg from '../images/5. Typography/Desktop/Toyota_Desktop_Typography_Subhead_Kerning.svg';
import Typography_Subhead_Leading_svg from '../images/5. Typography/Desktop/Toyota_Desktop_Typography_Subhead_Leading.svg';
import Typography_Subhead_Paragraph_Spacing_svg from '../images/5. Typography/Desktop/Toyota_Desktop_Typography_Subhead_Space_After.svg';
import Typography_Subhead_Weight_svg from '../images/5. Typography/Desktop/Toyota_Desktop_Typography_Subhead_Weight.svg';

const introText =
  'Toyota Type is our own distinctive font. It’s approachable, human, yet contemporary. Utilising Toyota Type throughout our communications will ensure consistency and recognition across all touchpoints.<br></br>The exception to this rule is internal Corporate Communications and emails where  Univers Light is to be used.';

const Typography = ({ location }) => {
  return (
    <Layout appLocation={location}>
      <Hero text={introText} navindex="0" image={hero_img} />
      <PageSection sectionId="toyota-type">
        <ContentBlock>
          <h2>Toyota Type</h2>
          <div className="text">
            <p>
              Toyota Type can be used for Brand Campaigns and the Messaging
              Platform.
            </p>
          </div>
          <h3>Primary font weights</h3>
          <div className="text">
            <p>
              There are four preferred and two optional weights for use within
              the Toyota Type family. Each weight includes uprights and italics.
              The selected and acceptable weights are Book, Regular, Semibold
              and Bold. While additional weights are available within the Toyota
              Type family, these are the only four that should be used unless a
              specific case otherwise demands.
            </p>
          </div>
          <FontSelector
            items={[
              {
                caption: 'Book',
                style: { fontFamily: 'ToyotaType-Book' }
              },
              {
                caption: 'Regular',
                style: { fontFamily: 'ToyotaType-Regular' }
              },
              {
                caption: 'Semibold',
                style: { fontFamily: 'ToyotaType-Semibold' }
              },
              {
                caption: 'Bold',
                style: {
                  fontFamily: 'ToyotaType-Regular',
                  fontWeight: 'bold'
                }
              }
            ]}></FontSelector>
        </ContentBlock>
        <ContentBlock noBottomSpacing={true}>
          <h3>Secondary font weights</h3>
          <div className="text">
            <p>
              The optional Toyota Type weights are Light and Black. These may be
              used when appropriate. Light should be used in instances where the
              communications are focused on enhanced technology, precision or
              the weight feels appropriate for the intended message.
            </p>
            <p>
              Black should be used in instances where stance and presence need
              to be reinforced, or the weight feels appropriate for the intended
              message.
            </p>
          </div>
          <FontSelector
            items={[
              {
                caption: 'Light',
                style: { fontFamily: 'ToyotaType-Light' }
              },
              {
                caption: 'Black',
                style: {
                  fontFamily: 'ToyotaType-Regular',
                  fontWeight: 'bold'
                }
              }
            ]}></FontSelector>
        </ContentBlock>
      </PageSection>
      <PageSection sectionId="typography-settings">
        <ContentBlock>
          <h2>TYPOGRAPHY SETTINGS</h2>
          <div className="text">
            <p>
              Below are guidelines for font weight, leading, kerning and
              alignment when using headlines.
            </p>
          </div>
        </ContentBlock>
        <ContentBlock noBottomSpacing={true}>
          <h3>1. Headlines</h3>
        </ContentBlock>
        <Tabs
          section="logo"
          backgroundColor="white"
          items={[
            {
              content: {
                style: 'image',
                image: {
                  svg: Typography_Headline_Weight_svg,
                  style: { height: '400px' }
                },
                caption: {
                  copy: 'Use Semibold for headlines.'
                }
              },
              label: 'Weight'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: Typography_Headline_Leading_svg,
                  style: { height: '400px' }
                },
                caption: {
                  copy: 'Use 130% auto leading for print and OOH. Use .09em line height for digital communications.'
                }
              },
              label: 'Leading'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: Typography_Headline_Kerning_svg,
                  style: { height: '400px' }
                },
                caption: {
                  copy: 'Use optical kerning with manual adjustments as needed for all print and OOH. Use 0 pixel letter-spacing for digital.'
                }
              },
              label: 'Kerning'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: Typography_Headline_Alignment_svg,
                  style: { height: '400px' }
                },
                caption: {
                  copy: 'Headlines may be left aligned or centered, but never right aligned.'
                }
              },
              label: 'Alignment'
            }
          ]}
        />
      </PageSection>
      <PageSection sectionId="subheads">
        <ContentBlock noBottomSpacing={true}>
          <h3>2. Subheads</h3>
          <div className="text">
            <p>
              Below are guidelines for font weight, leading, kerning and
              paragraph spacing for subheads.
            </p>
          </div>
        </ContentBlock>
        <Tabs
          section="subhead"
          backgroundColor="white"
          items={[
            {
              content: {
                style: 'image',
                image: {
                  svg: Typography_Subhead_Weight_svg,
                  style: { height: '400px' }
                },
                caption: {
                  copy: 'Always use Semibold weight for subheads.'
                }
              },
              label: 'Weight'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: Typography_Subhead_Leading_svg,
                  style: { height: '400px' }
                },
                caption: {
                  copy: 'Use 115% auto leading for print and OOH. Use .09em line height for digital communications.'
                }
              },
              label: 'Leading'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: Typography_Subhead_Kerning_svg,
                  style: { height: '400px' }
                },
                caption: {
                  copy: 'Use optical kerning with manual adjustments as needed for all print and OOH. Use 0 pixel letter spacing for digital.'
                }
              },
              label: 'Kerning'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: Typography_Subhead_Paragraph_Spacing_svg,
                  style: { height: '400px' }
                },
                caption: {
                  copy: 'For paragraph spacing use the paragraph palette in Adobe InDesign and Illustrator programs to set the space-after adjustments manually. Adjust space after to be twice the cap height of the body copy.'
                }
              },
              label: 'Paragraph Spacing'
            }
          ]}
        />
      </PageSection>
      <PageSection sectionId="body-copy">
        <ContentBlock noBottomSpacing={true}>
          <h3>3. Body Copy</h3>
          <div className="text">
            <p>
              Below are guidelines for font weight, leading and paragraph
              spacing for body copy.
            </p>
          </div>
        </ContentBlock>
        <Tabs
          section="subhead"
          backgroundColor="white"
          items={[
            {
              content: {
                style: 'image',
                image: {
                  svg: Typography_Body_Weight_svg,
                  style: { height: '400px' }
                },
                caption: {
                  copy: 'Use Book weight for body text 10 points or larger on light backgrounds. Use Regular weight for body text 10 points or smaller reversed out of dark backgrounds.'
                }
              },
              label: 'Weight'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: Typography_Body_Leading_svg,
                  style: { height: '400px' }
                },
                caption: {
                  copy: 'Use 130% auto leading for print and OOH.'
                }
              },
              label: 'Leading'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: Typography_Body_Paragraph_Spacing_svg,
                  style: { height: '400px' }
                },
                caption: {
                  copy: 'For paragraph spacing use the paragraph palette in Adobe InDesign and Illustrator programs to set the space-after adjustments manually. Adjust space after to be twice the cap height of the body copy.'
                }
              },
              label: 'Paragraph Spacing'
            }
          ]}
        />
      </PageSection>
      <PageSection sectionId="legal-text">
        <ContentBlock noBottomSpacing={true}>
          <h3>4. Legal Text</h3>
        </ContentBlock>
        <Tabs
          section="subhead"
          backgroundColor="white"
          items={[
            {
              content: {
                style: 'image',
                image: {
                  svg: Typography_Legal_Weight_svg,
                  style: { height: '400px' }
                },
                caption: {
                  copy: 'Use Book weight for legal text 6 points or larger on light backgrounds. Use Regular weight for legal text 6 points or smaller reversed out of dark backgrounds.'
                }
              },
              label: 'Weight'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: Typography_Legal_Leading_svg,
                  style: { height: '400px' }
                },
                caption: {
                  copy: 'Use 130% auto leading for print and OOH.'
                }
              },
              label: 'Leading'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: Typography_Legal_Alignment_svg,
                  style: { height: '400px' }
                },
                caption: {
                  copy: 'Use left alignment.'
                }
              },
              label: 'Alignment'
            }
          ]}
        />
      </PageSection>
      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Logo"
        previousUrl={pageLinks.logo}
        nextText="Next"
        nextHeader="Colours"
        nextUrl={pageLinks.colours}
      />
    </Layout>
  );
};
export default Typography;
