import React, { useState } from "react";

const FontSelector = (props) => {
  const { items } = props;
  const defaultStyle = {
    border: "none",
    borderBottom: "1px solid black",
    outline: "none",
    width: "100%",
    padding: "4px 4px 40px 4px",
    fontSize: "40px",
    lineHeight: "1.5em",
    fontFamily: "ToyotaType",
  };
  const [fontStyle, setFontStyle] = useState(defaultStyle);
  const fontSelectChange = (e) => {
    const idx = e.target.value - 1;
    if (items[idx]) {
      setFontStyle({ ...defaultStyle, ...items[idx].style });
    } else {
      setFontStyle({ ...defaultStyle });
    }
  };

  return (
    <div className="font-selector">
      <select onChange={(e) => fontSelectChange(e)}>
        <option key="0" value="0">
          Weight
        </option>
        {items.map((item, idx) => (
          <option key={idx + 1} value={idx + 1}>
            {item.caption}
          </option>
        ))}
      </select>
      <div className="copy" style={{ marginTop: "60px" }}>
        <input
          type="text"
          className="darker-background"
          placeholder="Type something here to try Toyota Type"
          style={fontStyle}
        ></input>
      </div>
    </div>
  );
};

export default FontSelector;
